<template>
  <div>
    <b-card title="资源信息">
      <div class="custom-search">
        <b-row class="mb-8">
          <b-col md="8">
            <b-form @submit="onSubmit" @reset="onReset" cols="5">
              <b-form-group id="input-group-1" label="编号id:" label-for="input-1" v-if="form.no">
                <b-form-input id="input-1" v-model="form.no" disabled type="number">
                </b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="名称:" label-for="input-2">
                <b-form-input id="input-2" v-model="form.name" type="text" placeholder="请输入名称" required>
                </b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" label="关联分组:" label-for="input-3">
<!--                <b-form-input id="input-3" :value="form.tenantPathStr" type="text" placeholder="请输入名称" required v-b-modal.modal-relation>
                </b-form-input>-->
                <div  @click="setGroupClick">
                  <v-select
                      :clearable="false"
                      placeholder="请选择分组"
                      ref="selectGroup"
                      :options="[]"
                      v-model="chooseGroupName"
                      v-b-modal.modal-relation
                  />
                </div>
              </b-form-group>

              <b-form-group id="input-group-4" label="资源类型:" label-for="input-4">
                <v-select
                  :reduce="val => val.value"
                  v-model="form.type"
                  :clearable="true"
                  :options="resourceTypeOptions"
                  placeholder="请选择资源类型"
                />
              </b-form-group>

              <b-form-group id="input-group-5" label="备注:" label-for="input-5" description="">
                <b-form-input id="input-5" v-model="form.note" type="text" placeholder="请输入备注">
                </b-form-input>
              </b-form-group>

<!--              <b-form-group id="input-group-6" label="特殊状态:" label-for="input-6">
                <v-select
                  :reduce="val => val.value"
                  v-model="form.status"
                  :clearable="true"
                  :options="statusOptions"
                />
              </b-form-group>-->
              <div v-if="this.$route.query.edit==false">
              </div>
              <div v-else>
                <b-button type="submit" variant="primary">保存</b-button>
                <!--<b-button type="reset" variant="danger" style="margin-left: 10px;">重置</b-button>-->
              </div>
            </b-form>
          </b-col>
        </b-row>
      </div>

      <b-modal id="modal-relation" title="修改分组" centered ok-only ok-title="确定" @ok="setTenantPath">
        <div>
          <el-tree ref="menuTree"  style="overflow:auto;max-height: 80vh" :props="props" :data="groups" node-key="id" :check-strictly="true" highlight-current default-expand-all :expand-on-click-node="false" @node-click="nodeClick" @check-change="handleCheckChange">
          <span class="custom-tree-node" slot-scope="{ node, data }">
              <span  >{{ data.name }}</span>
            </span>
          </el-tree>
        </div>
      </b-modal>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar,BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton,BDropdown,BDropdownItem,BPagination
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import request from '@/api/request'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BForm,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        tenantPathStr: "",
      },
      formTmp: {
      },
      resourceTypeOptions: [
        {
          label: "房间",
          value: 0,
        },
      ],
      statusOptions: [
        {
          label: "离院",
          value: 0,
        },
      ],

      props: {
        label: 'name',
        children: 'childNodes',
      },
      groups: [],
      groupsRequest: true,

      chooseGroup: null,
      chooseGroupTmp: null,
      chooseGroupName: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  created(){
    this.getTenantResourceTypes();
    this.getTenantResourceStatusEnum();
    if(this.$route.query && this.$route.query.id){
      this.getResourceInfo(this.$route.query.id)
    }
  },
  methods: {
    /**获取资源信息*/
    getResourceInfo(id){
      request.get('tob/tenant/tenantResource/getResourceInfoVo', {resourceId:id
      }).then(res => {
        if (res.data.success) {
          this.form = res.data.data;
          this.formTmp= res.data.data;
          this.chooseGroupName= this.form.tenantPathStr;
          console.log(" getResourceInfo", this.form)
        }
      })
    },
    onSubmit(event) {
      event.preventDefault()
      let url;
      let  flag;
      if (this.$route.query.edit === undefined){
        url = 'tob/tenant/tenantResource/addResource'
        flag='add';
      }else if (this.$route.query.edit){
        url = 'tob/tenant/tenantResource/updateResource'
        flag='update';
      }
      request.post(url, {
        name: this.form.name,
        type: this.form.type,
        status: this.form.status,
        tenantGroupId: this.chooseGroup,
        note: this.form.note,
        resourceId: this.form.id,
      }).then(res => {
        if (res.data.success) {
          this.chooseGroup=null;

          if (flag=='add'){
            this.$bvToast.toast("创建成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
            this.$route.query.edit=true;
            this.getResourceInfo(res.data.data);
          }else if (flag =='update'){
            this.$bvToast.toast("更新成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
            this.getResourceInfo(this.form.id);
          }
        }else {
          if (flag =='update'){
            this.getResourceInfo(this.form.id);
          }
        }

      })
    },
    onReset(event) {
      event.preventDefault()
      this.form = this.formTmp
    },

    getTenantResourceTypes(){
      request.get('tob/tenant/tenantResource/getTenantResourceTypes', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.resourceTypeOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: value - 0,
              label: key,
            }

            this.resourceTypeOptions.push(enumObject);
          }
        }
      })
    },

    getTenantResourceStatusEnum(){
      request.get('tob/tenant/tenantResource/getTenantResourceStatusEnum', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.statusOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: value - 0,
              label: key,
            }

            this.statusOptions.push(enumObject);
          }
        }
      })
    },

    groupsData() {
      request.get('tob/tenant/tenantGroup/listTenantGroupsById', {
      }).then(res => {
        if (res.data.success) {
          this.groups = res.data.data;
          this.groupsRequest=false;
        }
      })
    },

    setGroupClick(){
      if (this.groupsRequest){
        this.groupsData();
      }
    },

    nodeClick(data, node, v) {
      this.chooseGroupTmp = data.id
      this.chooseGroupName=data.name;
      this.$refs.menuTree.setCheckedKeys([data.id]);
    },

    handleCheckChange(item,checked,self){
      if(checked){
        this.chooseGroupTmp = item.id
        this.$refs.menuTree.setCheckedKeys([item.id]);
      }else{
        if(this.chooseGroupTmp === item.id){
          this.$refs.menuTree.setCheckedKeys([item.id]);
        }
      }
    },

    setTenantPath(params){
      //params.preventDefault();//禁止关闭
      this.chooseGroup = this.chooseGroupTmp
      this.form.tenantPathStr=this.chooseGroupName;
      this.chooseGroupTmp=null;
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
